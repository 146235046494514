.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.register-banner {
  height: 400px;
  /*background-image: url('./assets/static/images/banner-home.jpg');*/
  background-color: #f29600;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.MuiButton-containedSecondary {
  color: white !important;
  /*background-color: rgb(130, 54, 170) !important;*/
  background: linear-gradient(
          90deg
          , #8237aa 0%, #3d1152 100%) !important;
}

.MuiButton-outlinedSecondary {
  color: rgba(9, 9, 9, 0.81) !important;
  border: 1px solid rgb(3, 3, 3) !important;
}

.makeStyles-heroContent-2 {
  margin-top: 40px !important;
}

.container {
  margin-top: 90px;
}

.breadcrumb {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: .4rem 1rem;
  margin-bottom: 2rem;
  list-style: none;
  border-radius: 0rem;
  font-size: 0.8rem;
  font-weight: bolder;
}

.breadcrumb-item {
  display: -ms-flexbox;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

a {
  color: #3366FF;
}

.MuiTypography-body1 {
    color: black !important;
}

.MuiButton-label {
    font-weight: 700 !important;
}

.offer {
  color: #f5c300 !important;
}

.center {
  padding-left: 0px;
  text-align: center;
  list-style-position: inside;
}

.MuiTypography-colorTextSecondary {
    color: black !important;
}

.MuiCardHeader-title {
  font-weight: 700 !important;
  font-size: 1.4rem !important;
}

.logo-art {
  padding-right: 10px;
  background-color: #ffffff;
  padding-left: 10px;
  margin-right: 5px;
  color: black;
  font-style: normal;
  font-family: serif;
  border-radius: 3px;
}
.subheading{
  color: #6e1698 !important;
}

.question-images{
  max-height: 1000px;
  max-width: 900px;
}
.MuiButton-contained.Mui-disabled {
    color: rgba(0, 0, 0);
    box-shadow: none;
    background-color: rgba(128, 128, 128, 0.12) !important;
}

.StripeElement {
  border: 3px solid black;
  border-radius: 3px;
  padding: 16px 0px 16px 0px
}

.PrivateStripeElement{
    margin: 15px !important;
}

.MuiButton-fullWidth {
    padding: 16px
}

.MuiOutlinedInput-notchedOutline {
    /*border-color: rgb(0, 0, 0) !important;*/
    border: 3px solid black !important;
}

.MuiButton-contained.Mui-disabled {
    color: white !important;
    box-shadow: none !important;
    background-image: linear-gradient(to right, rgba(131, 54, 169, 0.25), rgba(236, 0, 140, 0.22), rgba(131, 54, 170, 0.24)) !important;
    background-color: #8236aa00!important;
    /*background-image: linear-gradient(to right, #9a9b9b, #cdcdcd, #9b9b9b) !important;*/
}

.div-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.list-style {
  border: 1px solid !important;
  border-radius: 4px !important;
}

.container-spacer {
  padding-top: 16px !important;
}

.last-viewed {
  color: #8237aa !important;
}

.not-last-viewed {
  color: #dac5e5 !important;
}

img[alt=drawing] { max-width: 100%; }
img[alt=img] {max-width: 100%}


img[alt=img-50] {
  width: 50px;
  max-width: 100%
}

img[alt=img-100] {
  width: 100px;
  max-width: 100%
}

img[alt=img-150] {
  width: 150px;
  max-width: 100%
}

img[alt=img-200] {
  width: 200px;
  max-width: 100%;
}

img[alt=img-400] {
  width: 400px;
  max-width: 100%;
}

img[alt=img-600] {
  width: 600px;
  max-width: 100%;
}

img[alt=img-800] {
  width: 800px;
  max-width: 100%;
}

img[alt=img-1000] {
  width: 1000px;
  max-width: 100%;
}
